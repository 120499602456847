import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {MessageService} from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  showLoader : boolean = false;

  constructor( private fb: FormBuilder,private messageService: MessageService, private router: Router) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      password: ['']
    });
  }

  validateLogin(){
    this.showLoader = true;
    if(this.loginForm.value.password == "2020"){
      localStorage.setItem('token','8de58984d14cce163c2d92c672ea669a')
      this.router.navigate(['homepage']);
      this.showLoader = false;
    }
    else{
      this.messageService.add({severity:'error', summary: 'Error Message', detail:'Enter Valid Password'});
      this.showLoader = false;
    }
  }

}
