<app-loader-service *ngIf='showLoader'></app-loader-service>
<!-- <button (click)="showUserDialog()" style="margin-left: 30px;" class="btn btn-primary">Add New User</button> -->


<!-- Users Table -->
<!-- <div class="table-responsive" style="padding: 30px;">
<p-table [rowHover]="true" [value]="users" [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5,10,15,20]"
  totalRecords="totalRecords">
  <ng-template pTemplate="header">
    <tr>
      <th>#</th>
      <th>Agent Name</th>
      <th>UserName</th>
      <th>Password</th>
      <th>Mobile No</th>
      <th>Edit</th>
      <th>Delete</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body">
    <tr *ngFor="let v of users">
      <td>{{v.id}}</td>
      <td>{{v.name}}</td>
      <td>{{v.username}}</td>
      <td>{{v.password}}</td>
      <td>{{v.mobileno}}</td>
      <td (click)="editUser(v)"><a class="btn btn-outline-warning">Edit</a></td>
      <td (click)="deleteUser(v)"><a class="btn btn-outline-danger">Delete</a></td>
    </tr>
  </ng-template>
</p-table>
</div> -->

<div class="table-responsive" style="padding: 30px;">
  <table class="table table-striped table-bordered">
    <thead class="thead-inverse">
      <tr>
        <th>#</th>
        <th>Agent Name</th>
        <th>UserName</th>
        <!-- <th>Password</th> -->
        <th>Mobile No</th>
        <th>Edit</th>
        <th>Delete</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let v of users">
        <td>{{v.id}}</td>
        <td>{{v.name}}</td>
        <td>{{v.username}}</td>
        <!-- <td>{{v.password}}</td> -->
        <td>{{v.mobileno}}</td>
        <td (click)="editUser(v)"><a class="btn btn-outline-warning">Edit</a></td>
        <td (click)="deleteUser(v)"><a class="btn btn-outline-danger">Delete</a></td>
      </tr>
    </tbody>
  </table>
</div>


<!-- User Edit Dialog -->



<!-- Add User Dialog -->

<p-dialog header="Create New User" [(visible)]="displayUser" [style]="{width: '60vw'}" modal="true">
  <form [formGroup]="addUserForm">

    <div class="form-group ">


      <label class="col-md-3 col-form-label"> User Name:</label>
      <div class="col-md-12">
        <input class="form-control" id="name" type="text" placeholder="Enter User Name (required)"
          formControlName="name" />
      </div>

      <label class="col-md-3 col-form-label"> User UserName:</label>
      <div class="col-md-12">
        <input class="form-control" id="username" type="text" placeholder="Enter User UserName (required)"
          formControlName="username" />
      </div>

      <label class="col-md-3 col-form-label"> Password:</label>
      <div class="col-md-12">
        <input class="form-control" id="password" type="password" placeholder="Enter User Password (required)"
          formControlName="password" />
      </div>

      <label class="col-md-3 col-form-label"> Mobile No:</label>
      <div class="col-md-12">
        <input class="form-control" id="mobileno" type="text" placeholder="Enter User MobileNo (required)"
          formControlName="mobileno" />
      </div>


      <br>

      <div class="form-group row mb-2">
        <div class="offset-md-5 col-md-5">
          <button *ngIf="addUserButton" class="btn btn-success mr-3" type="submit" [disabled]="!addUserForm.valid"
            style="width:80px" (click)="addUser()">Submit</button>
          <button *ngIf="editUserButton" class="btn btn-success mr-3" type="submit" [disabled]="!addUserForm.valid"
            style="width:80px" (click)="editUserSubit()">Submit</button>

          <button class="btn btn-primary mr-3 " type="reset" style="width:80px">Reset</button>

        </div>
      </div>
    </div>
  </form>

</p-dialog>
