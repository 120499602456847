<app-loader-service *ngIf='showLoader'></app-loader-service>

<div class="hold-transition lockscreen">
  <div class="lockscreen-wrapper">
    <div class="lockscreen-logo">
      <a href=""><b>Madhvi</b>Transport</a>
    </div>
    <!-- User name -->
    <div class="lockscreen-name">Admin</div>

    <!-- START LOCK SCREEN ITEM -->
    <div class="lockscreen-item">
      <!-- lockscreen image -->
      <div class="lockscreen-image">
        <img src="https://madhvi.in/images/Logo.png" alt="User Image">
      </div>
      <!-- /.lockscreen-image -->

      <!-- lockscreen credentials (contains the form) -->
      <form class="lockscreen-credentials form-group" [formGroup]="loginForm">
        <div class="input-group">
          <input id="password" type="password" class="form-control" placeholder="password" formControlName="password">

          <div class="input-group-append">
            <button type="button" class="btn" (click)="validateLogin()">
              <i class="fas fa-arrow-right text-muted"></i>
            </button>
          </div>
        </div>
      </form>
      <!-- /.lockscreen credentials -->

    </div>
    <!-- /.lockscreen-item -->
    <div class="help-block text-center">
      Enter your password to retrieve your session
    </div>
    <div class="text-center">
      <a href="">Or Contect Developer</a>
    </div>
    <div class="lockscreen-footer text-center">
      Copyright &copy; 2020-2021 <b><a href="https://madhvi.in/" class="text-black">Madhvi.in</a></b><br>
      All rights reserved
    </div>
  </div>
</div>

<p-toast></p-toast>
