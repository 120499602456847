import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})


export class ActivityService {

  url = 'http://thekuniyas.com/meapi/trasport/';

  header : any = {
    headers: new HttpHeaders()
      .append('Authorization',  `${localStorage.getItem('token')}`)
  }


  constructor(private http: HttpClient) { }

  getActivityById(id : any):any{
    return this.http.get<number>(this.url+"activities/getbyid.php?id="+id,this.header);
  }
}
