import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Users } from './model/users';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url = 'http://thekuniyas.com/meapi/trasport/';

  header: any = {
    headers: new HttpHeaders()
      .append('Authorization', `${localStorage.getItem('token')}`)
  }


  constructor(private http: HttpClient) {
  }

  getUserById(id: any): any {
    this.http.get<any>(this.url + 'users/getbyid.php' + "?id=" + id, this.header);
  }

  getUsers(): any {
    return this.http.get<any>(this.url + 'users/get.php', this.header);
  }

  deleteUser(v: any): any {
    return this.http.delete<number>(this.url + 'users/delete.php' + "?id=" + v.id)
  }

  addUser(users: Users): any {
    return this.http.post<any>(this.url + 'users/post.php', users, { responseType: 'text' as 'json' });
  }
  editUser(id: any, user: Users): any {
    return this.http.post<number>(this.url + "users/put.php?id=" + id, user, { responseType: 'text' as 'json' });
  }
}
