import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { Travel } from './model/travel';

@Injectable({
  providedIn: 'root'
})
export class TravelService {

  url = 'http://thekuniyas.com/meapi/trasport/';

  constructor(private http: HttpClient) { }

  getTravels(): any {
    return this.http.get<any>(this.url + "travels/get.php");
  }

  getOpenTravels() : any {
    return this.http.get<any>(this.url + "travels/getopen.php");
  }

  getCloseTravels() : any {
    return this.http.get<any>(this.url + "travels/getclose.php");
  }

  addTravels(travel: Travel): any {
    return this.http.post<any>(this.url + "travels/post.php", travel, { responseType: 'text' as 'json' })
  }

  deleteTravels(id: any): any {
    return this.http.delete<number>(this.url + "travels/delete.php?id=" + id);
  }

  editTravel(id: any, travel: Travel): any {
    return this.http.post<number>(this.url+"travels/put.php?tid=" + id, travel, { responseType: 'text' as 'json' });
  }
}
