<app-loader-service *ngIf='showLoader'></app-loader-service>


<div class="col-12 col-sm-12">
  <div class="card card-primary card-tabs">
    <div class="card-header p-0 pt-1">
      <ul class="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
        <li class="pt-2 px-3">
          <h3 class="card-title">Welcome to Madhvi Management</h3>
        </li>
        <li class="nav-item">
          <a class="nav-link active" id="custom-tabs-two-home-tab" data-toggle="pill" href="#custom-tabs-two-home"
            role="tab" aria-controls="custom-tabs-two-home" aria-selected="true">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="custom-tabs-two-profile-tab" data-toggle="pill" href="#custom-tabs-two-profile"
            role="tab" aria-controls="custom-tabs-two-profile" aria-selected="false">Users</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="custom-tabs-two-messages-tab" data-toggle="pill" href="#custom-tabs-two-messages"
            role="tab" aria-controls="custom-tabs-two-messages" aria-selected="false">Messages</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="custom-tabs-two-settings-tab" data-toggle="pill" href="#custom-tabs-two-settings"
            role="tab" aria-controls="custom-tabs-two-settings" aria-selected="false">Settings</a>
        </li>
      </ul>
    </div>
    <div class="card-body">
      <div class="tab-content" id="custom-tabs-two-tabContent">
        <div class="tab-pane fade active show" id="custom-tabs-two-home" role="tabpanel"
          aria-labelledby="custom-tabs-two-home-tab">

          <!-- Dashboard Card -->

          <div class="row mb-3" style="padding: 30px;">
            <div class="col-xl-3 col-sm-6 py-2">
              <div class="card bg-success text-white h-100">
                <div class="card-body bg-success">
                  <div class="rotate">
                    <i class="fa fa-user fa-4x"></i>
                  </div>
                  <h6 class="text-uppercase">Total Users</h6>
                  <h1 class="display-4">{{userLength}}</h1>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 py-2">
              <div class="card text-white bg-danger h-100">
                <div class="card-body bg-danger">
                  <div class="rotate">
                    <i class="fa fa-list fa-4x"></i>
                  </div>
                  <h6 class="text-uppercase">Active Jobs</h6>
                  <h1 class="display-4">{{travelLengthopen}}</h1>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 py-2">
              <div class="card text-white bg-info h-100">
                <div class="card-body bg-info">
                  <div class="rotate">
                    <i class="fa fa-plane fa-4x"></i>
                  </div>
                  <h6 class="text-uppercase">Totol Jobs</h6>
                  <h1 class="display-4">{{travelLength}}</h1>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 py-2">
              <div class="card text-white bg-warning h-100">
                <div class="card-body">
                  <div class="rotate">
                    <i class="fa fa-share fa-4x"></i>
                  </div>
                  <h6 class="text-uppercase">Closed Jobs</h6>
                  <h1 class="display-4">{{travelLengthclose}}</h1>
                </div>
              </div>
            </div>
          </div>


          <!-- add Job Button -->

          <button (click)="showUserDialog()" style="margin-left: 30px;" class="btn btn-primary">Add New User</button>
          <button (click)="showDialog()" style="margin-left: 30px;" class="btn btn-primary">Add New Job</button>
  
          <!-- Dropdown button -->
          <div class="btn-group" style="margin-left: 30px;">
            <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Filter
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" (click)="getTravelsList()">All</a>
              <a class="dropdown-item" (click)="getOpenTravelsList()">Open</a>
              <a class="dropdown-item" (click)="getCloseTravelsList()">Close</a>
            </div>
          </div>

          <!-- Dashboard Table -->
          <div class="table-responsive" style="padding: 30px;">
            <!-- <div class="form-group">
              <input type="text" class="form-control" placeholder="Search Here" [(ngModel)]="term">
          </div> -->
            <table class="table table-striped table-bordered">
              <thead class="thead-inverse">
                <tr>
                  <th>#</th>
                  <th>Assigned Agent</th>
                  <th>Job Location</th>
                  <th>Cost</th>
                  <th>Status</th>
                  <th>Edit</th>
                  <!-- <th>View</th> -->
                  <th>Delete</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let v of travels ">
                  <td (click)="viewActivity(v)">{{v.tid}}</td>
                  <td (click)="viewActivity(v)">{{v.user_name}}</td>
                  <td (click)="viewActivity(v)">{{v.tname}}</td>
                  <td (click)="viewActivity(v)">{{v.money}}</td>
                  <td>{{v.status == 1 ? 'Open' : 'Close'}}</td>
                  <td (click)="editTravels(v)"><a class="btn btn-outline-warning">Edit</a></td>
                  <!-- <td (click)="viewActivity(v)"><a class="btn btn-outline-warning">View</a></td> -->
                  <td (click)="deleteJob(v)"><a class="btn btn-outline-danger">Delete</a></td>
                </tr>

              </tbody>
            </table>
          </div>


<!-- Pagination Start -->
          <ul class="pagination">
            <li><a href="?pageno=1">First</a></li>
            <li >
                <a >Prev</a>
            </li>
            <li >
                <a >Next</a>
            </li>
            <li><a >Last</a></li>
        </ul>


        <!-- Pagination Stop -->



        </div>
        <div class="tab-pane fade" id="custom-tabs-two-profile" role="tabpanel"
          aria-labelledby="custom-tabs-two-profile-tab">
          <app-user></app-user>
        </div>
        <div class="tab-pane fade" id="custom-tabs-two-messages" role="tabpanel"
          aria-labelledby="custom-tabs-two-messages-tab">
          ac ornare magna.
        </div>
        <div class="tab-pane fade" id="custom-tabs-two-settings" role="tabpanel"
          aria-labelledby="custom-tabs-two-settings-tab">
          n hac habitasse platea dictumst. Praesent imperdiet accumsan ex sit amet facilisis.
        </div>
      </div>
    </div>
    <!-- /.card -->
  </div>
</div>


<!-- Create New Job Dialog -->

<p-dialog header="Create New Job" [(visible)]="display" [style]="{width: '60vw'}" modal="true">


  <form (ngSubmit)="onclick()" [formGroup]="addJobForm">

    <div class="form-group ">

      <input hidden class="form-control" id="status" type="text" formControlName="status" />

      <div class="col-md-4">
        <label>Select Agent:</label>
        <select class="form-control" formControlName="userid">
          <option value="" disabled>Choose Agent</option>
          <option *ngFor="let u of users" [ngValue]="u.id">{{u.name}}</option>
        </select>
      </div>


      <label class="col-md-3 col-form-label" for="categoryName"> Job Location:</label>
      <div class="col-md-12">
        <input class="form-control" id="tname" type="text" placeholder="Enter Job Location (required)"
          formControlName="tname" />
      </div>

      <label class="col-md-3 col-form-label" for="categoryName"> Coast:</label>
      <div class="col-md-12">
        <input class="form-control" id="money" type="text" placeholder="Enter Agent Name (required)"
          formControlName="money" />
      </div>


      <br>

      <div class="form-group row mb-2">
        <div class="offset-md-5 col-md-5">
          <button class="btn btn-success mr-3" type="submit" [disabled]="!addJobForm.valid"
            style="width:80px">Submit</button>

          <button class="btn btn-primary mr-3 " type="reset" style="width:80px">Reset</button>

        </div>
      </div>
    </div>
  </form>


</p-dialog>


<!-- Creare New User Dialog -->

<p-dialog header="Create New User" [(visible)]="displayUser" [style]="{width: '60vw'}" modal="true">
  <form (ngSubmit)="addUser()" [formGroup]="addUserForm">

    <div class="form-group ">


      <label class="col-md-3 col-form-label"> Name:</label>
      <div class="col-md-12">
        <input class="form-control" id="name" type="text" placeholder="Enter Name (required)" formControlName="name" />
      </div>

      <label class="col-md-3 col-form-label"> UserName:</label>
      <div class="col-md-12">
        <input class="form-control" id="username" type="text" placeholder="Enter UserName (required)"
          formControlName="username" />
      </div>

      <label class="col-md-3 col-form-label"> Password:</label>
      <div class="col-md-12">
        <input class="form-control" id="password" type="password" placeholder="Enter User Password (required)"
          formControlName="password" />
      </div>

      <label class="col-md-3 col-form-label"> Mobile No:</label>
      <div class="col-md-12">
        <input class="form-control" id="mobileno" type="text" placeholder="Enter User MobileNo (required)"
          formControlName="mobileno" />
      </div>


      <br>

      <div class="form-group row mb-2">
        <div class="offset-md-5 col-md-5">
          <button class="btn btn-success mr-3" type="submit" [disabled]="!addJobForm.valid"
            style="width:80px">Submit</button>

          <button class="btn btn-primary mr-3 " type="reset" style="width:80px">Reset</button>

        </div>
      </div>
    </div>
  </form>

</p-dialog>



<p-dialog header="Edit Travel Details" [(visible)]="displayEditTravel" [style]="{width: '60vw'}" modal="true">
  <form [formGroup]="editJobForm">

    <div class="form-group ">

      <div>
        <h5 style="color: red;">Note : Please Always Select Status before CLick on Submit Button</h5>
      </div>
      <div class="col-md-4">
        <label>Change Status:</label>
        <select class="form-control" formControlName="status">
          <option value="" disabled>Select Status</option>
          <option [ngValue]="1">Open</option>
          <option [ngValue]="0">Close</option>
        </select>
      </div>

      <input hidden [(ngModel)]="inputTid" class="form-control" id="tid" type="text" formControlName="tid" />

      <!-- <input [(ngModel)]="inputStatus" class="form-control" id="status" type="text" formControlName="status" /> -->


      <label class="col-md-3 col-form-label" for="categoryName"> Coast:</label>
      <div class="col-md-12">
        <input [(ngModel)]="inputMoney" class="form-control" id="money" type="text" formControlName="money" />
      </div>


      <br>

      <div class="form-group row mb-2">
        <div class="offset-md-5 col-md-5">
          <button class="btn btn-success mr-3" type="submit" [disabled]="!addJobForm.valid" style="width:80px"
            (click)="editJob()">Submit</button>

          <button class="btn btn-primary mr-3 " type="reset" style="width:80px">Reset</button>

        </div>
      </div>
    </div>
  </form>
</p-dialog>



<p-dialog header="Activity" [(visible)]="displayActivity" [style]="{width: '65vw'}" modal="true">
  <button class="btn btn-outline-success" (click)="captureScreen()">Open/Print Voucher</button>
  <h5 style="text-align: center;">Total Used Money : {{totalUsedMoney}}</h5>
  <div id="exportthis">
    <div class="table-responsive" style="padding: 30px;">
      <table class="table table-striped table-bordered">
        <thead class="thead-inverse">
          <tr>
            <th>#</th>
            <th>Travel Id</th>
            <th>Activity Name</th>
            <th>Expanse</th>
            <th>Date</th>
            <th>Time</th>
            <th>Location</th>
            <th>Photo</th>
            <th>Map</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let v of activity">
            <td>{{v.id}}</td>
            <td>{{v.travelid}}</td>
            <td>{{v.name}}</td>
            <td>{{v.money}}</td>
            <td>{{v.date}}</td>
            <td>{{v.time}}</td>
            <td>{{v.location}}</td>
            <td>
              <a target="_blank" href="http://thekuniyas.com/meapi/trasport/images/{{v.photo}}">
                <img width="100" height="100" src="http://thekuniyas.com/meapi/trasport/images/{{v.photo}}"
                  alt="{{v.name}}"></a>
            </td>
            <td>
              <div (click)="viewMap(v)"><i class="fa fa-map-marker" aria-hidden="true"></i></div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>


</p-dialog>