import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { UserService } from '../user.service';
import { Users } from '../model/users';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [MessageService]
})
export class UserComponent implements OnInit {

  displayUser: boolean = false;
  addUserForm: FormGroup;
  users: Users;
  addUserButton: boolean = false;
  editUserButton: boolean = false;
  showLoader : boolean = false;
  totalRecords : any;

  constructor(private userServices: UserService, private messageService: MessageService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.addUserForm = this.fb.group({
      id: [''],
      name: [''],
      username: [''],
      password: [''],
      mobileno: ['']
    });
    this.getUserList();
  }

  showUserDialog() {
    this.displayUser = true;
  }

  deleteUser(v: any) {
    if (confirm('Are you sure you want to delete user ' + v.name + '?')) {
      this.showLoader = true;
      this.userServices.deleteUser(v).subscribe(
        alert("User Deleted Successfully"),
        window.location.reload(),
        this.showLoader = false
      );
    }
  }

  editUser(u: any) {
    this.addUserForm.controls['id'].setValue(u.id);
    this.addUserForm.controls['name'].setValue(u.name);
    this.addUserForm.controls['username'].setValue(u.username);
    this.addUserForm.controls['password'].setValue(u.password);
    this.addUserForm.controls['mobileno'].setValue(u.mobileno);
    this.editUserButton = true;
    this.displayUser = true;
  }

  editUserSubit() {
    this.editUserPerform(this.addUserForm.value.id, this.addUserForm.value);
  }

  editUserPerform(id: any, value: any) {
    this.showLoader = true;
    this.userServices.editUser(id, value).subscribe(
      success => {
        alert("Updated Succesfully");
        window.location.reload();
        this.showLoader = false;
      },
      error => {
        alert("Something Went Wrong");
        this.showLoader = false;
      }
    );
  }

  getUserList() {
    this.userServices.getUsers().subscribe(
      d => {
        this.users = d;
        this.totalRecords = d.length;
        localStorage.setItem('userLength', d.length);
      }
    )
  }

  addUser() {
    this.addUserButton = true;
    this.showLoader = true;
    this.userServices.addUser(this.addUserForm.value).subscribe(
      success => {
        alert(this.addUserForm.value.name + " User Added Successfully");
        window.location.reload();
        this.showLoader = false;
      },
      error => {
        alert("Something went wrong");
        this.showLoader = false;
      }
    )
  }

}
