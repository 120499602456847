import { Component, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { Travel } from '../model/travel';
import { TravelService } from '../travel.service';
import { UserService } from '../user.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Users } from '../model/users';
import { from, Observable } from 'rxjs';
import { ActivityService } from '../activity.service';
import { Activity } from '../model/activity';
import { error } from '@angular/compiler/src/util';
import { Router } from '@angular/router';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { formatDate } from '@angular/common';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
  providers: [MessageService]
})
export class HomepageComponent implements OnInit {

  travels: Travel[];
  user: Users;
  travelslist = [];
  display: boolean = false;
  displayUser: boolean = false;
  displayActivity: boolean = false;
  displayEditTravel: boolean = false;
  addJobForm: FormGroup;
  addUserForm: FormGroup;
  editJobForm: FormGroup;
  travelLength: any;
  travelLengthopen: any;
  travelLengthclose: any;
  activity: Activity[];
  userLength: any = localStorage.getItem('userLength');
  users: Users;
  inputStatus: any;
  inputMoney: any;
  inputTid: any;
  showLoader: boolean = false;
  today = new Date();
  jstoday = '';
  tampMobile: any;
  tampName: any;
  tampMoney: any;
  tampLocation: any;
  activityTotal: any = 0;
  totalUsedMoney: any;
  totalAvailableMoney: any;
  term: string;


  constructor(private router: Router, private activityService: ActivityService, private userServices: UserService, private messageService: MessageService, private fb: FormBuilder, private locationStrategy: LocationStrategy, private travelServices: TravelService) {
    this.jstoday = formatDate(this.today, 'dd-MM-yyyy', 'en-US', '+0530');
  }

  ngOnInit(): void {
    if (localStorage.getItem('token') == null) {
      this.router.navigate(['login']);
    }
    this.addJobForm = this.fb.group({
      userid: [''],
      tname: [''],
      money: [''],
      status: [1],
    });

    this.addUserForm = this.fb.group({
      name: [''],
      username: [''],
      password: [''],
      mobileno: ['']
    });

    this.editJobForm = this.fb.group({
      tid: [''],
      money: [''],
      status: [''],
    })

    this.preventBackButton();
    this.getTravelsList();
    this.getUserList();
  }
  buildTableBody(data, columns) {
    var body = [];

    body.push(columns);

    data.forEach(function (row) {
      var dataRow = [];

      columns.forEach(function (column) {
        dataRow.push(row[column].toString());
      })

      body.push(dataRow);
    });

    return body;
  }
  table(data, columns) {
    return {
      table: {
        headerRows: 1,
        body: this.buildTableBody(data, columns)
      }
    };
  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }
  public async captureScreen() {
    this.totalAvailableMoney = this.tampMoney - this.totalUsedMoney;

    const documentDefinition = {
      content: [
        {
          columns: [
            {
              image: await this.getBase64ImageFromURL(
                "https://i.ibb.co/Jmm1ZSn/Madhvi-Logo-2-1.png"
              ),
              width: 240,
              height: 60
            },
            { text: "\n" },
            [
              {
                text: 'VOUCHAR',
                style: 'invoiceTitle',
                width: '*'
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        text: 'Vouchar',
                        style: 'invoiceSubTitle',
                        width: '*'

                      },
                      {
                        text: this.activity[0].travelid,
                        style: 'invoiceSubValue',
                        width: 100

                      }
                    ]
                  },
                  {
                    columns: [
                      {
                        text: 'Date',
                        style: 'invoiceSubTitle',
                        width: '*'
                      },
                      {
                        text: this.jstoday,
                        style: 'invoiceSubValue',
                        width: 100
                      }
                    ]
                  }
                ]
              }
            ],
          ],
        },
        { text: "\n" },
        // Billing Headers
        {
          columns: [
            {
              text: 'Vouchar From',
              style: 'invoiceBillingTitle',

            },
            {
              text: 'Vouchar To',
              style: 'invoiceBillingTitle',

            },
          ]
        },
        // Billing Details
        {
          columns: [
            {
              text: 'Bharatkumar Prajapati \n Madhvi Dairy Private Limited',
              style: 'invoiceBillingDetails'
            },
            {
              text: this.tampName + '\n' + this.tampMobile,
              style: 'invoiceBillingDetails'
            },
          ]
        },
        // Billing Address Title
        {
          columns: [
            {
              text: 'Address',
              style: 'invoiceBillingAddressTitle'
            },
            {
              text: 'Work Amount & Location',
              style: 'invoiceBillingAddressTitle'
            },
          ]
        },
        // Billing Address
        {
          columns: [
            {
              text: 'H.O. Pratiti House \n Opp. Adarsh School, Dairy Road 385001 \n   Palanpur',
              style: 'invoiceBillingAddress'
            },
            {
              text: this.tampMoney + '\n' + this.tampLocation,
              style: 'invoiceBillingAddress'
            },
          ]
        },
        '\n',
        {
          text: 'CIN No. : U15200GJ2014PTC078099'
        },
        { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 2 }] },
        // Line breaks
        '\n',
        // Items
        this.table(this.activity, ['name', 'money', 'date', 'time', 'location']),
        '\n',
        { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 2 }] },
        '\n',
        {
          text: 'Total Used Money :' + " " + this.totalUsedMoney
        },
        {
          text: 'Total Available Money :' + " " + this.totalAvailableMoney
        },
        '\n',
        { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 2 }] },
        '\n\n\n\n\n\n\n\n\n',
        {
          columns: [
            { text: 'AUTHORISED SIGN.  ---------------------------------', style: 'documentFooterLeft' },
            { text: 'Stamp Ticket', style: 'documentFooterRight' },
          ]
        }
      ],
    };
    pdfMake.createPdf(documentDefinition).print();
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }

  viewMap(v: any) {
    var url = "https://maps.google.com/?q=" + v.latitude + "," + v.longitude;
    window.open(url);
  }

  editTravels(v: any) {
    this.displayEditTravel = true;
    this.editJobForm.value.tid = v.tid;
    this.editJobForm.value.money = v.money;
    this.editJobForm.value.status = v.status;
    this.inputMoney = v.money;

    this.inputStatus = v.status;
    this.inputTid = v.tid;
  }
  editJob() {
    this.showLoader = true;
    this.travelServices.editTravel(this.editJobForm.value.tid, this.editJobForm.value).subscribe(
      success => {
        this.showLoader = false;
        alert("Updated Succesfully");
        window.location.reload();
      },
      error => {
        alert("Something Went Wrong");
      }
    );
  }

  getUserList() {
    this.userServices.getUsers().subscribe(
      d => {
        this.users = d;
      }
    )
  }

  viewActivity(v: Users) {
    this.tampName = v.name;
    this.tampMobile = v.mobileno;
    this.tampLocation = v.tname;
    this.tampMoney = v.money;
    this.showLoader = true;
    this.activityService.getActivityById(v.tid).subscribe(
      d => {
        this.showLoader = false;
        this.displayActivity = true;
        this.activity = d;
        var total = this.ConvertStringToNumber('0');
        for (var i = 0; i < this.activity.length; i++) {
          total = total + this.ConvertStringToNumber(this.activity[i].money);
        }
        console.log(total);
        this.totalUsedMoney = total;
      }
    );
  }

  ConvertStringToNumber(input: string) {
    var numeric = Number(input);
    return numeric;
  }

  deleteJob(v: any) {
    if (confirm('Are you sure you want to delete user ' + v.name + '?')) {
      this.travelServices.deleteTravels(v.tid).subscribe(
        alert("Job Deleted Successfully"),
        window.location.reload(),
      );
    }
  }

  getTravelsList() {
    this.showLoader = true;
    this.travelServices.getTravels().subscribe(
      d => {
        this.showLoader = false;
        this.travels = d;
        this.travelLength = this.travels.length;
      }
    );
  }
  getOpenTravelsList() {
    this.showLoader = true;
    this.travelServices.getOpenTravels().subscribe(
      d => {
        this.showLoader = false;
        this.travels = d;
        this.travelLengthopen = this.travels.length;
      }
    );
  }
  getCloseTravelsList() {
    this.showLoader = true;
    this.travelServices.getCloseTravels().subscribe(
      d => {
        this.showLoader = false;
        this.travels = d;
        this.travelLengthclose = this.travels.length;
      }
    );
  }

  getUser(id: any) {
    this.userServices.getUserById(id).subscribe(
      (u: any) => {
      }
    )
  }



  showDialog() {
    this.display = true;
  }

  showUserDialog() {
    this.displayUser = true;
  }

  onclick(): any {
    this.showLoader = true;
    this.travelServices.addTravels(this.addJobForm.value).subscribe(
      success => {
        this.showLoader = false;
        alert(this.addJobForm.value.tname + " Travel Added Successfully");
        window.location.reload();
      },
      error => {
        alert("Something went wrong");
      }
    )

  }

  addUser() {
    this.showLoader = true;
    this.userServices.addUser(this.addUserForm.value).subscribe(
      success => {
        this.showLoader = false;
        alert(this.addUserForm.value.name + " User Added Successfully");
        window.location.reload();
      },
      error => {
        alert("Something went wrong");
      }
    )
  }

}
